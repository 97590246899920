import { shallowRef, computed, onMounted } from "vue";
import { usePage, router } from "@inertiajs/vue3";

// User/account specific globals here only

const $page = usePage();

const isLoaded = shallowRef(null);

const isLoggedIn = computed(() => $page.props?.logged_in);
const contact = computed(() => $page.props?.contact?.data ?? null);

const setUserType = () => {
  const pushDataLayer = (data) => {
    if (window?.dataLayer) {
      window.dataLayer.push(function () {
        this.reset();
      });
      window.dataLayer.push(data);
    }
  };

  onMounted(() => {
    if (isLoaded.value) return;

    isLoaded.value = true;

    router.on("navigate", () => {
      const data = {};

      if (isLoggedIn.value) {
        // custobar
        data.cb_customer_id = contact.value?.number ?? "";
        data.user_type = "regular";
      } else {
        data.user_type = "guest";
      }

      if (["79.171.225.204", "79.171.225.208"].includes($page.props?.clientIp) || (contact.value?.email ?? "").includes("@varusteleka")) {
        data.user_type = "internal";
      }

      pushDataLayer(data);
    });
  });
};

export const useAccount = () => {
  return {
    isLoggedIn,
    contact,
    setUserType,
  };
};
