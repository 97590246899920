<template>
  <div class="z-10 fixed inset-0 transition-opacity">
    <div class="absolute inset-0 bg-black bg-opacity-30 cursor-pointer" tabindex="0" @click="close"> </div>
  </div>
  <div class="absolute mx-auto  max-w-medium top-full inset-x-0 z-20">
    <div class="relative bg-accent-dark text-white w-full  ">
      <div class="">
        <div class="grid grid-cols-6  overflow-hidden">
          <!-- Main Menu -->
          <div class="relative ">
            <div class="flex flex-col gap-1.5 py-10 pb-10  relative text-sm" :class="overflow">
              <span v-for="item in selectedMenuItem.children" :key="item.number" 
                class="flex cursor-pointer border-b border-[#323232] xl:pl-4 pr-4" :class="selectStyle(item)" @click="mouseenter(item)"
             >
                <div class="uppercase px-4 pt-1.5 pb-2"  >
                  {{ item.name }}
                </div>
                <div  :class="selectStyle2(item)" class="absolute right-[-2px] mt-[13px] transform -translate-y-1/2 w-[10px] h-[10px]  border-l-2 border-b-2 border-[#323232] rotate-45 bg-[#333333] z-[1]"></div>
              </span>
              
            </div>
             
            <div class="absolute top-0 right-0 h-full w-1 -mr-[1px] border-l border-[#323232] bg-[#333333]">
              
            </div>
          </div>
          <div class="col-span-5 pl-3.5 pr-4 py-16 pb-40 bg-[#333333] relative" :class="overflow">
            <div class="absolute top-6 left-12 text-sm hover:underline font-semibold "><Link :href="selectedItem.value"> {{ selectedItem.name }}  </Link></div>
            <div class="columns-4 xl:columns-5 pl-6 ">
             

              <template v-if="selectedItemChildren.length">
                <div v-for="item in selectedItemChildren" :key="item.number" class="break-inside-avoid"
                  :class="{ 'mb-6': item?.children.length }">
                  <div class="flex flex-col justify-center pb-6">
                    <div class="py-[3px] px-2.5">
                      <Link :href="item.value" class="font-semibold text-sm hover:underline">
                        <div class="flex items-center ">

                          <img v-if="item.picture?.pictureThumbUrl" :src="item.picture?.pictureThumbUrl" 
                            class="w-10 h-10 rounded-full object-cover mr-2 border-br">
                            <img v-else src="/varusteleka/media/articlePlaceHolder.webp"
                            class="w-10 h-10 rounded-full object-cover mr-2 border-br">
                          {{ item.name }}
                       

                        </div>
                      </Link>
                    </div>
                    <template v-if="item?.children.length">
                      <div v-for="child in item.children" :key="child.number" class="py-[3px] pl-16">
                        <Link :href="child.value" class="text-sm cursor-pointer hover:underline">
                          {{ child.name }}
                        </Link>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { shallowRef, ref, computed, watch, onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "g/Composables/GlobalStore.js";

const props = defineProps({
  activeMenu: {
    type: [Number, Boolean],
    required: true,
  },
});

const overflow = ' overflow-hidden overflow-y-auto';

const selectStyle = (item) => {
  return selectedItem.value?.id === item?.id ? 'underline' : '';
};

const selectStyle2 = (item) => {
  return selectedItem.value?.id === item?.id ? 'block' : 'hidden';
};

const selectedItem = shallowRef(null);

const store = useStore();

const firstParentMenu = computed(() => {
  return store['main'].find(item => item.id === props.activeMenu);
});

const selectedItemChildren = computed(() => {
  let a = [];
  let b = [];
  if (selectedItem.value && selectedItem.value?.children.length) {
    selectedItem.value.children.forEach(child => {
      if (child?.children.length) {
        a.push(child);
      } else {
        b.push(child);
      }
    });
  } else {
    return [];
  }
  return [...a, ...b];
});

const parentMenus = ref([firstParentMenu.value]);

// Watch for changes in activeMenu prop
watch(() => props.activeMenu, () => {
  // Update parentMenus
  parentMenus.value = [firstParentMenu.value];
  
  // Reset selectedItem
  selectedItem.value = null;

  // Set selectedItem to the first child of the new selectedMenuItem if available
  if (selectedMenuItem.value && selectedMenuItem.value.children?.length) {
    selectedItem.value = selectedMenuItem.value.children[0];
  }
});

const selectedMenuItem = computed(() => {
  const lastParentMenu = parentMenus.value[parentMenus.value.length - 1];
  return lastParentMenu || [];
});

// Watch for changes in selectedMenuItem to set the first child item as selected
watch(selectedMenuItem, (newValue) => {
  if (newValue && newValue.children?.length && !selectedItem.value) {
    selectedItem.value = newValue.children[0];
  }
}, { immediate: true });

const emit = defineEmits(['hide']);
const close = () => emit('hide');

var timeout;
const mouseenter = (item) => {
  if(!item.children.length){
    if (item.value) {
      window.location.href = item.value; 
      return; 
    }
  }
  selectedItem.value = item;

  clearTimeout(timeout);

  
};

const handleEsc = (e) => {
  if (overlay.megamenu && e.code === "Escape") {
    overlay.megamenu = null;
  }
};

onBeforeMount(() => document.addEventListener("keydown", handleEsc));
onBeforeUnmount(() => document.removeEventListener("keydown", handleEsc));

</script>
