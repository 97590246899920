import {shallowRef} from 'vue';
const blockUI = shallowRef(false);

export function useLoading() {
  const setLoading = (value) => {
    blockUI.value = value;
  };

  return {
    blockUI,
    setLoading,
  };
}
