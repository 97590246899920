import { effectScope, onScopeDispose } from "vue";

/**
 * Make a composable function usable with multiple Vue instances.
 * This is a edited version of:
 * @see https://vueuse.org/createSharedComposable
 */
export const createSharedComposable = (composable, disposeInt = 0) => {
  let subscribers = 0;
  let state;
  let scope;

  const dispose = () => {
    if (scope && --subscribers <= disposeInt) {
      scope.stop();
      subscribers = 0;
      state = undefined;
      scope = undefined;
      // console.log('disposed:', composable);
    }
  };

  return (...args) => {
    subscribers++;
    if (!scope) {
      scope = effectScope(true);
      state = scope.run(() => composable(...args));
    }
    onScopeDispose(dispose, true);
    return state;
  };
};
