/* eslint-disable no-undef */
import { shallowReactive, watch, computed, onBeforeMount, onBeforeUnmount, toRaw } from "vue";
import { usePage, router } from "@inertiajs/vue3";
import { blockEditorblocks, promotionCreativeNames } from "g/Scripts/Analytics/Constants.js";
import { custobarEvents } from "g/Scripts/Analytics/Custobar.js";
import { useCart } from "g/Composables/Cart.js";

// TODO
// TODO!: Vaatii TODELLA ison refaktoroinnin!
// TODO

const CUSTOBAR = new custobarEvents();

const SSR = import.meta.env.SSR;

const $page = usePage();
const { cart } = useCart();

const cartItems = computed(() => {
  if (!cart.value) return [];
  const items = (cart.value?.cartitems ?? []).map(function (row, index) {
    let item = {
      item_id: $page?.props?.analytics?.useNumber ? row?.products_number : row.products_id,
      item_name: row.name,
      item_brand: row.brand?.name ?? "",
      item_group_id: row.analytics.item_group_id,
      currency: row.analytics.price_data.currency,
      discount: parseFloat(Math.max(0, row.analytics.price_data.discount)),
      price: parseFloat(row.analytics.price_data.price_without_discount),
      index: index + 1,
      quantity: row.amount_cart,
    };

    let groups = row.groupNames ?? [];
    if (groups.length) {
      groups.forEach((group, index) => {
        item[index ? "item_category" + (index + 1) : "item_category"] = group;
      });
    }

    return item;
  });
  // console.log(items);
  return items;
});

const currency = computed(() => cart.value?.analytics.price_data?.currency ?? $page.props?.currency?.shortName ?? "EUR");

const contactGroups = computed(() => {
  const contact = $page.props?.contact?.data ?? null;
  let groups = "";
  if (contact?.groups?.length) {
    groups = contact.group_numbers.sort().join(",");
  }
  return groups;
});

// HELPERS

function isArray(thing) {
  return thing != null && thing.constructor && thing.constructor.name === "Array";
}

function isObject(thing) {
  return thing != null && thing.constructor && thing.constructor.name === "Object";
}

function gtagEnabled() {
  return import.meta.env?.VITE_GOOGLE_GTAG_ACCOUNT && typeof gtag === "function";
}

function gtmEnabled() {
  return import.meta.env?.VITE_GOOGLE_GTM_ACCOUNT && window?.dataLayer;
}

function hasConsent() {
  // checks if cookie banner has been interacted with
  const consent = localStorage.getItem("cookieConsent");
  if (!consent || consent === undefined) {
    return false;
  }
  return true;
}

function getConsent() {
  // gets consent value
  // this key should be same everywhere
  const consent = localStorage.getItem("cookieConsent");
  if (!consent || ["null", "undefined", "false"].includes(consent)) {
    return false;
  }
  return true;
}

// END HELPERS

var pageChangeInterval;

export function useAnalytics() {
  if (import.meta.env.VITE_SEND_ANALYTICS !== "true" || typeof window === "undefined") {
    return false;
  }

  // console.log("Using analytics");
  // Navigation events

  // comment this beforeMount if the one below is used
  onBeforeMount(() => router.on("navigate", pageChange));

  /*
  HOX: disabled/reverted hasConsent check for now, as this can also be handled inside tagmanager according to leka
  There are two more places where related disabled code is found as well gsend & pageChange
  */

  // onBeforeMount(() => {
  //   router.on("navigate", (event) => {
  //     if (!hasConsent()) {
  //       pageChangeInterval = setInterval(() => {
  //         let triggered = hasConsent(); // retry if consent has been modified
  //         if (triggered) {
  //           clearInterval(pageChangeInterval);
  //         }
  //         pageChange(event); // trigger missed first navigation;
  //       }, 3000);
  //       return;
  //     }
  //     pageChange(event);
  //   });
  // });

  onBeforeUnmount(() => {
    clearInterval(pageChangeInterval);
  });

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events
  // https://developers.google.com/tag-platform/devguides/consent

  const gtag_pageview = function (analyticsEvent, pageComponentName, pageProps, pageUrl) {
    // TELL CUSTOMERS TO DISABLE historyChange events/triggers in gtm for faster page loads
    customHistoryChange();

    // HOX: lähtee automaattisesti, manuaalinen  määritys vaatii:
    // if (gtagEnabled()) {
    //   gtag('config', import.meta.env?.VITE_GOOGLE_GTAG_ACCOUNT, { send_page_view: false });
    // }
    // gsend('page_view', { 'page_title': document?.title ?? "", 'page_location': window.location.origin + pageUrl });

    // Facebook page view event
    if (window.fbq) {
      window.fbq("track", "PageView", { eventID: $page.props.eventId });
    }

    const contact = $page.props.contact?.data;
    const analyticsUseNumber = $page?.props?.analytics?.useNumber;

    //Tämä aina kun sivu latautuu
    switch (analyticsEvent) {
      case "login":
        gsend("login", {
          method: "standard",
        });

        if (contact) {
          gsend("user_info", {
            user_id: contact?.number || 0,
            user_group: contactGroups.value,
          });
        }

        break;
      case "register":
        gsend("sign_up", {
          method: "standard",
        });
        break;
    }

    if (Array.isArray(CUSTOBAR.page.view)) {
      const eventData = CUSTOBAR.page;
      eventData.view.forEach((event) => {
        event.append({
          label: eventData.labels(pageComponentName),
        });
      });
    }

    // PAGE STUFF
    if (pageComponentName === "Article") {
      // console.log(pageComponentName);

      let listName = pageProps.article.title ?? "";

      let blocks = pageProps?.blocks ?? [];
      if (blocks.length) {
        let blockProductSliders = blocks.filter((item) => item.name == blockEditorblocks.BLOCK_PRODUCT_SLIDER) ?? [];
        if (blockProductSliders?.length) {
          blockProductSliders.forEach((slider) => {
            let products = slider.props?.elements?.products ?? [];
            let ln = slider.props?.elements?.name ?? listName;
            viewItemListEventObserver(`[data-id="${slider.id}"]`, products, ln);
          });
        }

        // TODO block lazy product sliders
      } else {
        // TODO merge of article children products
        let products = pageProps.products?.data ?? [];
        viewItemListEventObserver(null, products, listName);
      }
    } else if (pageComponentName === "GiftCard") {
      const giftcard = pageProps.giftcard;

      let currency = giftcard.values?.[0]?.currency ?? "EUR";
      let value = parseFloat(giftcard.values?.[0]?.value ?? 0);

      let item = {
        item_id: giftcard.productId,
        item_name: giftcard.name,
        item_brand: `${window.location.host} ${giftcard.name.toLowerCase()}`,
        item_group_id: giftcard.productId,
        affiliation: window.location.host ?? "",
        /* leka says no */
        // item_list_id: `${giftcard.name.toLowerCase().split(' ').join('_')}_page`,
        // item_list_name: `${giftcard.name} page`,
        item_variant: giftcard.templates?.[0]?.pictureUrl ?? "",
        currency: currency,
        discount: 0,
        price: value,
        quantity: 1,
      };

      //HOX - GIFTCARD HAS NO DEFAULT GROUPS THEREFORE BREADCRUMBS
      let groups = pageProps.breadcrumbs
        .map((crumb, index) => {
          if (index) {
            return crumb.name;
          }
        })
        .filter((n) => n);

      if (groups.length) {
        groups.forEach((group, index) => {
          item[index ? "item_category" + (index + 1) : "item_category"] = group;
        });
      }

      let data = {
        currency: currency,
        items: [item],
      };

      gsend("view_item", setEcommerce(data));

      if (Array.isArray(CUSTOBAR.page.view)) {
        CUSTOBAR.page.view.forEach((event) => {
          event.append({
            product_id: giftcard.productId,
          });
        });
      }

      // Facebook product page view event
      // TODO eventId
      // if (window.fbq) {
      //   window.fbq('track', 'ViewContent', {
      //     content_name: giftcard.name,
      //     content_category: (groups && groups.length) ? groups.join(" > ") : "",
      //     content_ids: [giftcard.productId],
      //     content_type: 'product',
      //     value: value,
      //     currency: currency,
      //   }, { eventID: $page.props.eventId });
      // }
    } else if (pageComponentName === "Frontpage") {
      let blocks = pageProps?.blocks ?? [];

      let viewPromotion = "view_promotion";

      // BLOCKEDITOR ITEM SLIDERS
      if (blocks.length) {
        let blockProductSliders = blocks.filter((item) => item.name == blockEditorblocks.BLOCK_PRODUCT_SLIDER) ?? [];
        if (blockProductSliders?.length) {
          blockProductSliders.forEach((slider) => {
            let products = slider.props?.elements?.products ?? [];
            let listName = slider.props?.elements?.name ?? "Frontpage";
            viewItemListEventObserver(`[data-id="${slider.id}"]`, products, listName);
          });
        }

        let filter = (array, key) => array.filter((item) => item.name == key) ?? [];

        let blockSlider = filter(blocks, blockEditorblocks.BLOCK_SLIDER);
        if (blockSlider?.length) {
          blockSlider.forEach((element) => {
            let slots = element.slots?.default ?? [];
            if (slots?.length) {
              // DYNAMIC BANNERS
              blockDynamicBanners(viewPromotion, filter(slots, blockEditorblocks.BLOCK_DYNAMIC_BANNER));
              // DEFAULT BANNERS
              blockDefaultBanners(viewPromotion, filter(slots, blockEditorblocks.BLOCK_DEFAULT_BANNER));
            }
          });
        }

        //BLOCKS WITHOUT PARENT

        // DYNAMIC BANNERS
        blockDynamicBanners(viewPromotion, filter(blocks, blockEditorblocks.BLOCK_DYNAMIC_BANNER));

        // DEFAULT BANNERS
        blockDefaultBanners(viewPromotion, filter(blocks, blockEditorblocks.BLOCK_DEFAULT_BANNER));

        // DEFAULT GROUP PROMOTIONS
        blockDefaultGroups(viewPromotion, filter(blocks, blockEditorblocks.BLOCK_GROUP_HIGHLIGHT));
      } else {
        // WEBSHOP PAGE SETTINGS

        // PRODUCT SLIDERS
        let productSliders = pageProps?.products ?? [];
        if (productSliders.length) {
          productSliders.forEach((slider) => {
            let products = slider?.products ?? [];
            let listName = slider?.name ?? "Frontpage";
            // viewItemListEvent(products, listName);
            viewItemListEventObserver(null, products, listName);
          });
        }

        // BANNERS
        let banners = Object.values(pageProps?.banners?.["banner"] ?? {}) ?? [];
        if (banners.length) {
          banners.forEach((banner, index) => {
            let item = getPromotionObject(banner, index, promotionCreativeNames.BANNER);
            anyPromotionEvent(viewPromotion, item);
          });
        }

        // NOSTOT
        let promos = Object.values(pageProps?.banners?.["promo"] ?? {}) ?? [];
        if (promos.length) {
          promos.forEach((promo, index) => {
            let item = getPromotionObject(promo, index, promotionCreativeNames.PROMO);
            anyPromotionEvent(viewPromotion, item);
          });
        }
      }
    } else if (pageComponentName === "Product") {
      let item = {
        item_id: analyticsUseNumber ? pageProps.product.number : pageProps.product.id,
        item_name: pageProps.product.name,
        item_brand: pageProps.product.brand?.name ?? "",
        item_group_id: pageProps.product.analytics.item_group_id,
        affiliation: window.location.host ?? "",
        /* leka says no */
        // item_list_id: `${pageComponentName.toLowerCase().split(' ').join('_')}_page`,
        // item_list_name: `${pageComponentName} page`,
        currency: pageProps.product.analytics.price_data.currency,
        discount: parseFloat(pageProps.product.analytics.price_data.discount),
        price: parseFloat(pageProps.product.analytics.price_data.price_without_discount),
        availability: pageProps.product.analytics.availability ?? "",
        quantity: 1,
      };

      let groups = pageProps.breadcrumbs
        .map((crumb, index) => {
          if (index && index < pageProps.breadcrumbs.length - 1) {
            return crumb.name;
          }
        })
        .filter((n) => n);

      if (groups.length) {
        groups.forEach((group, index) => {
          item[index ? "item_category" + (index + 1) : "item_category"] = group;
        });
      }

      let data = {
        currency: pageProps.product.analytics.price_data.currency,
        // value: parseFloat(pageProps.product.analytics.price_data.price), //// Removed leka says no
        items: [item],
      };

      gsend("view_item", setEcommerce(data));

      // Custobar product view event
      if (window.cstbr) {
        if (window.cstbrConfig) {
          window.cstbrConfig.productId = pageProps.product.id;
        }
      }
      if (Array.isArray(CUSTOBAR.page.view)) {
        CUSTOBAR.page.view.forEach((event) => {
          event.append({
            product_id: pageProps.product.id,
            product_ean: pageProps.product.ean,
          });
        });
      }

      // Facebook product page view event
      if (window.fbq) {
        window.fbq(
          "track",
          "ViewContent",
          {
            content_name: pageProps.product.name,
            content_category: groups && groups.length ? groups.join(" > ") : "",
            content_ids: [pageProps.product.id],
            content_type: "product",
            value: parseFloat(pageProps.product.analytics.price_data.price),
            currency: pageProps.product.analytics.price_data.currency,
          },
          { eventID: $page.props.eventId },
        );
      }
    } else if (pageComponentName === "Cart") {
      //let cartTotal = parseFloat((cart.value?.display_price.total_price.total_price_with_vat ?? "0").replace(/[^0-9.,]/g, '').replace(',', '.'));

      let data = {
        currency: cart.value?.analytics.price_data.currency,
        value: cart.value?.analytics.price_data.price,
        items: cartItems.value,
      };

      gsend("begin_checkout", setEcommerce(data));
    } else if (pageComponentName === "ProductList") {
      const url = pageProps.lazyload_url;

      const pageType = shallowReactive({
        isSearch: url.includes("/search/"),
        isBrand: url.includes("/brands/"),
        isCampaign: url.includes("/campaigns/"),
      });
      pageType.isCategory = Object.keys(pageType).every((k) => !pageType[k]);

      if (pageType.isCampaign) {
        return;
      }

      let listName = pageType.isBrand ? "Product collection" : (pageProps?.name ?? "");

      if (pageType.isSearch) {
        listName = pageProps.analytics?.hasKlevu ? "Klevu Search Results Page" : "Search results";

        gsend("search", {
          search_term: pageProps.search_term ?? "",
        });
      }

      let products = pageProps.products?.data ?? [];

      viewItemListEventObserver(null, products, listName);
    } else if (pageComponentName === "Confirmation") {
      if (analyticsEvent === "confirmation") {
        const summary = pageProps.order.summary;

        if (!summary || !Array.isArray(summary.rows)) {
          return false;
        }

        const items = summary.rows.map((row) => {
          let item = {
            item_id: analyticsUseNumber ? row?.products_number : row.products_id,
            item_name: row.name,
            item_brand: row?.brand?.name ?? "",
            item_group_id: row.analytics.item_group_id,
            item_variant: row.analytics.item_variant,
            currency: row.analytics.price_data.currency,
            discount: parseFloat(row.analytics.price_data.discount),
            price: parseFloat(row.analytics.price_data.price_without_discount),
            quantity: parseInt(row.amount_cart),
          };

          let groups = row?.groupNames ?? [];
          if (groups.length) {
            groups.forEach((group, index) => {
              item[index ? "item_category" + (index + 1) : "item_category"] = group;
            });
          }

          return item;
        });

        // reset dataLayer here instead for purchase event instead of gsend method
        resetDataLayer();

        // Enhanced conversions
        enhancedConversions(summary);
        // send user data and enchanced conversions before purchase event so user_data is accessible for leka

        if (contact) {
          var a = {
            user_id: contact?.number || 0,
            user_group: contactGroups.value,
          };
        }

        let b = {
          transaction_id: summary.order_number,
          value: summary.total,
          tax: summary.total_tax,
          shipping: summary.shipping,
          currency: summary.currency,
          items: items,
        };

        if (contact) {
          gsend("purchase", setEcommerce(a, b), "event", false);
        } else {
          gsend("purchase", setEcommerce(b), "event", false);
        }

        // Facebook purchase event
        if (window.fbq) {
          const ids = items.map((item) => item.item_id);
          window.fbq("track", "Purchase", { content_ids: ids, content_type: "product", currency: summary.currency, value: summary.total }, { eventID: $page.props.eventId });
        }
      }
    }

    // push custobar view event data
    if (Array.isArray(CUSTOBAR.page.view)) {
      CUSTOBAR.page.view.forEach((event) => {
        event.push(); // push is empty here as all data should already be appended above
      });
    }
  };

  const pageChange = (event) => {
    //HOX: return false if no consent is disable for now
    // if (!getConsent()) {
    //   // console.log('disabled');
    //   return;
    // }

    // reset data layer before events
    resetDataLayer();

    // reset old observers at page change
    resetObservers();

    const analyticsEvent = event.detail.page.props.flash?.event ?? "";
    const pageComponentName = event.detail.page.component;
    const pageProps = $page.props;
    const pageUrl = event.detail.page.url;

    setTimeout(() => gtag_pageview(analyticsEvent, pageComponentName, pageProps, pageUrl), 150);
  };
}

function customHistoryChange() {
  // DISABLE events/triggers in gtm and use this event only:
  // gtm.historyChange
  // gtm.historyChange-v2

  const data = {
    newTitle: document.title,
    newUrl: window.location.href,
    oldUrl: $page.props?.prevUrl ?? "",
  };

  gsend("historyChange", data);
}

function resetDataLayer() {
  // Vaikuttais että propsit ei tule mukana kun kutsuu resettiä ennen eventtiä:
  // https://developers.google.com/tag-platform/tag-manager/datalayer#custom_data_layer_methods
  if (window?.dataLayer && Object.keys(window?.dataLayer ?? {}).length) {
    // console.log('reset');
    window.dataLayer.push(function () {
      this.reset();
    });
  }
}

function setEcommerce(a, b = null) {
  // this function adds ecommerce element when gtm
  var data = { ecommerce: a };
  if (b) {
    data = Object.assign({ ...a }, { ecommerce: b });
  }
  return data;
}

function delEcommerce(data) {
  // this function removes ecommerce element when default gtag
  let _data = { ...data };
  if (_data?.ecommerce) {
    let a = { ..._data["ecommerce"] };
    delete _data["ecommerce"];
    _data = Object.assign(_data, a);
  }
  return _data;
}

function gsend(event, data, type = "event", reset = true) {
  // Generalized push to data layer function
  // According to googles recommendation webshops should use either GTM or GA4 not both (unless GA is sourced within tagmanager script)

  // HOX: return false if no consent disabled for now
  // if (!getConsent()) {
  //   return;
  // }

  // reset dataLayer prior to new event
  if (reset) {
    resetDataLayer();
  }

  if (gtmEnabled()) {
    // gtm
    let _data = { ...data };
    // Is this necessary if datalayer is reset anyway?
    // if (data?.ecommerce) {
    //   window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    // }
    _data[type] = event;
    window.dataLayer.push(_data);
  }

  if (gtagEnabled()) {
    // gtag
    let _data = delEcommerce(data);
    gtag(type, event, _data);
  }
}

function anyListEvent(products, listName = "") {
  if (!products?.length) return;

  const pageProps = $page.props;
  const analyticsUseNumber = pageProps?.analytics.useNumber;

  const listId = listName.toLowerCase().split(" ").join("_");

  const items = products
    .map((product, index) => {
      if (!product?.id) return undefined;

      let item = {
        item_id: analyticsUseNumber ? product.number : product.id,
        item_name: product.name,
        item_brand: product.brand?.name ?? "",
        item_group_id: product.analytics.item_group_id,
        currency: product.analytics.price_data.currency,
        affiliation: window.location.host ?? "",
        discount: parseFloat(product.analytics.price_data.discount),
        price: parseFloat(product.analytics.price_data.price_without_discount),
        /* leka says no */
        // item_list_id: listId,
        item_list_name: listName,
        index: index + 1,
        quantity: 1,
      };

      let groups = product?.groupNames ?? [];
      if (groups.length) {
        groups.forEach((group, index) => {
          item[index ? "item_category" + (index + 1) : "item_category"] = group;
        });
      }

      return item;
    })
    .filter(Boolean);

  let a = {
    item_list_id: listId,
    item_list_name: listName,
  };

  let b = {
    items: items,
  };

  return setEcommerce(a, b);
}

function selectItemEvent(product, listName = "") {
  const data = anyListEvent([product], listName);
  // console.log(data);
  if (data && Object.keys(data).length) {
    gsend("select_item", data);
  }
}

function viewItemListEvent(products, listName = "") {
  const data = anyListEvent(products, listName);
  // console.log(data);
  if (data && Object.keys(data).length) {
    gsend("view_item_list", data);
  }
}

function getPromotionObject(item, index, creativeName = "") {
  if (item) {
    return {
      id: item.id,
      name: item?.name ?? "",
      creativeName: creativeName,
      index: index,
    };
  }
}

function anyPromotionEvent(event, item) {
  if (event && item) {
    let data = {
      promotion_id: item.id,
      promotion_name: item.name,
      creative_name: item.creativeName,
      creative_slot: item.index,
    };

    // console.log(event, setEcommerce(data));

    gsend(event, setEcommerce(data));
  }
}

function cartShippingPaymentChanged(eventData) {
  const lx = (event, props) => {
    var data = {
      currency: currency.value,
      value: parseFloat(cart.value.analytics.price_data.price ?? 0),
      items: cartItems.value,
    };

    Object.assign(data, props);

    gsend(event, setEcommerce(data));
  };

  const shipping = eventData?.shipping;
  const payment = eventData?.payment;

  if (shipping && shipping?.name) {
    lx("add_shipping_info", {
      shipping_tier: shipping.name,
    });
  }

  if (payment && payment?.name) {
    lx("add_payment_info", {
      payment_type: payment.name,
    });
  }
}

function flashItemEvent(event, data) {
  if (data?.items?.length) {
    let a = {
      value: data.value ?? "",
      currency: data.items?.[0]?.analytics?.price_data?.currency ?? currency.value,
    };

    let b = { ...{ items: data.items } };

    gsend(event, setEcommerce(a, b));
  }
}

function enhancedConversions(order) {
  const orderInfo = $page.props.order?.info;

  if (orderInfo && isObject(orderInfo)) {
    if (gtmEnabled()) {
      resetDataLayer();
      window.dataLayer.push({ user_data: orderInfo });
    }

    if (gtagEnabled()) {
      gtag("set", "user_data", orderInfo);

      const conversionId = import.meta.env.VITE_GOOGLE_ADS_CONVERSION_ID;
      const conversionLabel = import.meta.env.VITE_GOOGLE_ADS_CONVERSION_LABEL;

      if (conversionId && conversionLabel) {
        gtag("event", "conversion", {
          send_to: conversionId + "/" + conversionLabel,
          value: order.total,
          currency: order.currency,
          transaction_id: order.order_number,
        });
      }
    }
  }
}

watch(
  () => $page.props?.flash?.event,
  (eventName) => {
    const eventData = $page.props?.flash?.eventData;
    // console.log(eventName, eventData);

    switch (eventName) {
      case "addToCart":
        if (Array.isArray(CUSTOBAR.cart.add)) {
          CUSTOBAR.cart.add.forEach((event) => {
            let data = { product_id: eventData.productId };
            event.push(data);
          });
        }

        //Facebook
        if (window.fbq) {
          window.fbq("track", "AddToCart", { content_ids: eventData.items.map((item) => item.item_id) }, { eventID: $page.props.eventId }); //TODO CHECK THAT IDS GO IN HERE
        }
        flashItemEvent("add_to_cart", eventData);
        break;
      case "removeFromCart":
        CUSTOBAR.cart.remove.push({ product_id: eventData.productId });
        flashItemEvent("remove_from_cart", eventData);
        break;
      // HOX deprecated in favor of click listener in observer loop
      case "selectItem":
        flashItemEvent("select_item", eventData);
        break;
      case "shippingPaymentChanged":
        cartShippingPaymentChanged(eventData);
        break;
    }
  },
  { deep: true },
);

// BLOCKEDITOR FUNCTIONS

function defaultBlockElementForPromotion(event, array, creativeName = "") {
  array.forEach((arrayItem, index) => {
    var [name, text] = "";

    if (Object.keys(arrayItem?.mutatedProps ?? {}).length) {
      let props = arrayItem.mutatedProps;
      name = props?.["headingText"]?.default ?? props?.["text"]?.default ?? props?.["group"]?.name ?? "";
    } else {
      let slots = arrayItem?.slots?.default ?? [];

      if (slots?.length) {
        let headingProps = slots.find((item) => item?.name === blockEditorblocks.BLOCK_HEADING)?.props ?? [];
        let descriptionProps = slots.find((item) => item.name === blockEditorblocks.BLOCK_TEXT)?.props ?? [];

        name = headingProps["headingText"]?.default ?? "";
        text = descriptionProps["text"]?.default ?? "";
      }
    }

    var item = {
      id: arrayItem.id,
      name: name ?? text,
      creativeName: creativeName,
      index: index,
    };

    if (event.includes("view_promotion")) {
      viewPromotionEventObserver(`[data-id="${arrayItem.id}"]`, [event, item]);
    } else {
      anyPromotionEvent(event, item);
    }
  });
}

function blockDynamicBanners(event, array) {
  if (array.length) {
    array.forEach((banner, index) => {
      let props = toRaw(banner.props ?? []);
      let analytics = props?.["analytics"] ?? null;

      if (banner) {
        let item = getPromotionObject(analytics, index, promotionCreativeNames.BANNER);
        if (event.includes("view_promotion")) {
          viewPromotionEventObserver(`[data-id="${banner.id}"]`, [event, item]);
        } else {
          anyPromotionEvent(event, item);
        }
      }
    });
  }
}

function blockDefaultBanners(event, array) {
  if (array?.length) {
    defaultBlockElementForPromotion(event, array, promotionCreativeNames.BANNER);
  }
}

function blockDefaultGroups(event, array) {
  if (array?.length) {
    let newArray = [];

    array.forEach((parent) => {
      let slots = parent.slots?.default ?? [];
      let items = [...(slots.find((item) => item.name == blockEditorblocks.BLOCK_COLUMN)?.slots?.default ?? [])];

      if (items?.length) {
        items.forEach((child, index) => {
          let props = toRaw(child.props);

          let blocks = {
            group: props?.["group"],
            text: props?.["text"],
            headingText: props?.["headingText"],
          };

          for (const [key] of Object.entries(blocks)) {
            if (!blocks[key]) {
              delete blocks[key];
            }
          }

          items[index].mutatedProps = Object.keys(blocks)?.length ? blocks : {};
        });
        newArray = [...newArray, ...items];
      }
    });

    if (!newArray.length) {
      newArray = array;
    }

    defaultBlockElementForPromotion(event, newArray, promotionCreativeNames.PROMO);
  }
}

export function useAnalyticsBlockEvents() {
  const selectPromotionEvent = (parent) => {
    var blocks = $page.props?.blocks ?? [];

    if (blocks.length) {
      const find = (array) => array.find((item) => item?.name === (parent?.name ?? ""));

      var item = find(blocks);
      // if item not found search for match in children slots
      if (!item) {
        for (let index = 0; index < blocks.length; index++) {
          const element = blocks[index];
          let slots = [...(element["slots"]?.["default"] ?? [])];
          let child = find(slots);
          if (child) {
            item = child;
            break;
          }
        }
      }

      if (item) {
        const event = "select_promotion";
        const array = [item];

        switch (parent.name) {
          case blockEditorblocks.BLOCK_DEFAULT_BANNER:
            blockDefaultBanners(event, array);
            break;
          case blockEditorblocks.BLOCK_DYNAMIC_BANNER:
            blockDynamicBanners(event, array);
            break;
          case blockEditorblocks.BLOCK_GROUP_HIGHLIGHT:
            blockDefaultGroups(event, array);
            break;
        }
      }
    }
  };

  return {
    selectPromotionEvent,
  };
}

// END BLOCKEDITOR FUCTIONS

//ACTION EVENTS

export function useAnalyticsEvents() {
  const selectPromotionEvent = (item, index, creativeName = "") => {
    if (item) {
      let data = {
        promotion_id: item.id,
        promotion_name: item.name,
        creative_name: creativeName,
        creative_slot: index,
      };

      gsend("select_promotion", setEcommerce(data));
    }
  };

  const viewCartEvent = () => {
    if (cart.value) {
      let data = {
        currency: currency.value,
        value: parseFloat(cart.value?.analytics.price_data?.price ?? 0),
        items: cartItems.value,
      };

      gsend("view_cart", setEcommerce(data));
    }
  };

  const searchEvent = (value) => {
    gsend("search", {
      search_term: value ?? "",
    });
  };

  const addShippingInfoEvent = (shipping) => {
    if (shipping?.id) {
      let data = {
        shipping: {
          id: shipping.id,
          name: shipping.name,
        },
      };

      cartShippingPaymentChanged(data);
    }
  };

  const addPaymentInfoEvent = (payment) => {
    if (payment?.id) {
      let data = {
        payment: {
          id: payment.id,
          name: payment.name,
        },
      };

      cartShippingPaymentChanged(data);
    }
  };

  const addToWishlistEvent = (product) => {
    if (product?.id) {
      const analyticsUseNumber = $page?.props?.analytics?.useNumber;

      let item = {
        item_id: analyticsUseNumber ? product.number : product.id,
        item_name: product.name,
        item_brand: product.brand?.name ?? "",
        affiliation: window.location.host ?? "",
        currency: product.analytics.price_data.currency,
        discount: parseFloat(product.analytics.price_data.discount),
        price: parseFloat(product.analytics.price_data.price_without_discount),
        quantity: 1,
      };

      let groups = product?.groupNames ?? [];
      if (groups?.length) {
        groups.forEach((group, index) => {
          item[index ? "item_category" + (index + 1) : "item_category"] = group;
        });
      }

      let data = {
        currency: product.analytics.price_data.currency,
        items: [item],
      };

      gsend("add_to_wishlist", setEcommerce(data));

      CUSTOBAR.wishlist.add.push({ product_id: product.id });
    }
  };

  const removeFromWishlistEvent = (product) => {
    if (product?.id) {
      CUSTOBAR.wishlist.remove.push({ product_id: product.id });
    }
  };

  const emptyCartEvent = () => {
    CUSTOBAR.cart.empty.push();
  };

  const addToCartGiftcardEvent = (formData, variant) => {
    if (formData?.value && variant) {
      const giftcard = $page.props.giftcard;

      let currency = giftcard.values?.[0]?.currency ?? "EUR";
      let value = parseFloat(formData.value);

      let item = {
        item_id: giftcard.productId,
        item_name: giftcard.name,
        item_brand: `${window.location.host} ${giftcard.name.toLowerCase()}`,
        item_group_id: giftcard.productId,
        affiliation: window.location.host ?? "",
        /* leka says no */
        // item_list_id: `${giftcard.name.toLowerCase().split(' ').join('_')}_page`,
        // item_list_name: `${giftcard.name} page`,
        item_variant: variant,
        currency: currency,
        discount: 0,
        price: value,
        quantity: 1,
      };

      //HOX - GIFTCARD HAS NO DEFAULT GROUPS THEREFORE BREADCRUMBS
      let groups = $page.props.breadcrumbs
        .map((crumb, index) => {
          if (index) {
            return crumb.name;
          }
        })
        .filter((n) => n);

      if (groups.length) {
        groups.forEach((group, index) => {
          item[index ? "item_category" + (index + 1) : "item_category"] = group;
        });
      }

      let data = {
        currency: currency,
        items: [item],
      };

      gsend("add_to_cart", setEcommerce(data));
    }
  };

  return {
    selectItemEvent,
    selectPromotionEvent,
    searchEvent,
    viewCartEvent,
    viewItemListEvent,
    addPaymentInfoEvent,
    addShippingInfoEvent,
    addToWishlistEvent,
    removeFromWishlistEvent,
    emptyCartEvent,
    addToCartGiftcardEvent,
  };
}

// END ACTION EVENTS

// OBSERVERS

const observers = shallowReactive({});

function resetObservers() {
  for (let index in observers) {
    if (observers[index]) {
      observers[index].disconnect();
      delete observers[index];
    }
  }
}

function generateObserverIndex() {
  return Object.keys(observers)?.length ?? 0;
}

function waitForNodes(selectors = []) {
  if (SSR) return null;

  return new Promise((resolve) => {
    let nodes = document.querySelectorAll(selectors);
    if (nodes?.length) {
      resolve(nodes);
    }

    let index = generateObserverIndex();
    observers[index] = new MutationObserver(() => {
      let nodes = document.querySelectorAll(selectors);
      if (nodes?.length) {
        observers[index].disconnect();
        resolve(nodes);
      }
    });

    observers[index].observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

async function createVNodeObserver(stringOrNodes, options = { callback: null, callback2: null, params: [] }) {
  if (SSR) return;

  var vNodes = stringOrNodes;

  if (typeof vNodes === "string" || Array.isArray(vNodes)) {
    vNodes = await waitForNodes(vNodes);
  } else if (!Array.isArray(vNodes)) {
    vNodes = [vNodes];
  }

  // console.log(stringOrNodes, vNodes);

  if (!(vNodes?.[0] instanceof Element)) return;

  const observerOptions = {
    root: null,
    rootMargin: `0px 0px 0px 0px`,
    threshold: 0.75,
  };

  var [p1, p2, p3, p4] = [...options.params];

  const observerIndex = generateObserverIndex();
  observers[observerIndex] = new IntersectionObserver((entries) => {
    var intersections = [];
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        observers[observerIndex].unobserve(entry.target);
        if (typeof options.callback === "function") {
          if (p3 === "itemList") {
            let a = p1.find((item) => item.link === entry.target?.getAttribute("href") ?? "");
            if (a) {
              intersections.push(a);
            }
          } else {
            intersections = p1;
          }
        }
      }
    });

    if ((intersections && !Array.isArray(intersections)) || intersections?.length) {
      options.callback(intersections, p2, p3, p4);
    }

    if (!entries?.length) {
      observers[observerIndex].disconnect();
    }
  }, observerOptions);

  vNodes.forEach((element, loopIndex) => {
    if (element) {
      // attach click eventListener here for "select_item" event.
      if (options?.callback2) {
        element.addEventListener("click", () => {
          // this assumes params 0 is always a products array and same order as mapped hrefs in viewItemListEventObserver method
          let product = options["params"][0][loopIndex];
          let listName = options["params"][1];
          options.callback2(product, listName);
          // selectItemEvent(product, listName);
        });
      }
      observers[observerIndex].observe(element);
    }
  });
}

function viewItemListEventObserver(elements, products = [], listName = "") {
  if (products && products?.length) {
    let key = "";
    let hrefs = products.map((item) => `a[href="${item.link}"]`);
    if (hrefs?.length) {
      // observe individual containers
      elements = hrefs;
      key = "itemList";
    }

    if (!elements) return;

    createVNodeObserver(elements, { callback: viewItemListEvent, callback2: selectItemEvent, params: [products, listName, key] });
  }
}

function viewPromotionEventObserver(elements, data) {
  if (elements && data?.length) {
    createVNodeObserver(elements, { callback: anyPromotionEvent, params: data });
  }
}

export function useAnalyticsObservers() {
  return {
    viewItemListEventObserver,
  };
}
