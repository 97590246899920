import { shallowReactive, ref, computed, watch, toRaw, onBeforeMount, defineAsyncComponent } from "vue";
import { createSharedComposable } from "g/Scripts/Helpers/CreateSharedComposable.js";
import { usePage, useForm, router } from "@inertiajs/vue3";
import { useAnalyticsEvents } from "g/Composables/Analytics.js";
import { loadScript } from "vue-plugin-load-script";
import { updateShippingAndPayment } from "g/Scripts/Functions.js";
import { useLoading } from "g/Composables/BlockUI.js";

const SSR = import.meta.env.SSR;

const $page = usePage();
const { blockUI } = useLoading();
const { emptyCartEvent } = useAnalyticsEvents();

const useCart = createSharedComposable(() => {
  const cache = shallowReactive({
    cart: {},
    timestamp: 0,
  });

  const setCart = (cart) => {
    cache.cart = cart ?? {};
    cache.timestamp = $page.props.timestamp;
  };

  const cart = computed({
    get: () => {
      if (cache.timestamp < $page.props?.timestamp) {
        setCart($page.props.cart);
      }
      return cache.cart;
    },
    set: (cart) => {
      if (cart?.cartitems) {
        setCart(cart);
      }
    },
  });

  return {
    cart,
    setCart,
  };
});

const isCartPage = () => {
  if ($page.component !== "Cart") {
    let message = "Only works in Cart.vue";
    console.log(message);
    throw new Error(message);
  }
  return true;
};

const useCartPage = createSharedComposable(() => {
  if (!isCartPage()) return;

  const { setCart } = useCart();

  let timeout;

  const cartForm = useForm({
    shipping_address: $page.props.shipping_address,
    billing_address: $page.props.billing_address,
    shipping_id: $page.props.defaultShipping,
    payment_id: $page.props.defaultPayment,
    authorization_token: "",
    government_id: "",
    active_customer: null,
    newsletter: null,
  });

  setCart($page.props.cart);

  const pickupPoints = ref($page.props?.pickup_points ?? []);
  const salesDeliveries = ref($page.props?.salesDeliveries ?? []);
  const defaultShipping = ref($page.props.shippingMethods.find((shipping) => shipping.id === $page.props.defaultShipping));
  const defaultPayment = ref(defaultShipping?.value?.paymentMethods?.find((payment) => payment.id === $page.props.defaultPayment));
  const paymentPayload = ref($page.props?.payment_payload ?? {});
  const landedCostAccepted = ref($page.props?.landedCostAccepted ?? null);

  const reloadCart = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      router.reload({
        onFinish: () => {
          setCart($page.props.cart);
          salesDeliveries.value = $page.props?.salesDeliveries ?? [];
          blockUI.value = false;
        },
      });
    }, 100);
  };

  const clearCart = () => {
    router.visit("/cart/clear");
    emptyCartEvent();
  };

  // If cartStepper = true validation is done in CartStepper.vue
  const submitCart = (htmlForm, cartStepper = null) => {
    if (cartForm.processing || !defaultPayment.value || !defaultShipping.value) {
      return false;
    }

    // htmlForm is a ref to main form in Cart.vue

    // TODO if cartStepper skip validity check
    if ((htmlForm && htmlForm.checkValidity()) || cartStepper) {
      blockUI.value = true;

      // This requires useKlarnaPayments imported (from this file) and used in Cart.vue
      if (defaultPayment.value && defaultPayment.value.integration === "klarna_payments") {
        router.reload({
          onSuccess: () => {
            window.Klarna.Payments.authorize({ payment_method_category: paymentPayload.value.data.payment_method_category }, toRaw(paymentPayload.value.data.payment_details), (response) => {
              // console.log("Token:" + response.authorization_token);
              // console.log("Approved: :" + response.approved);
              // console.log(response);
              if (response.authorization_token && response.approved == true) {
                cartForm.authorization_token = response.authorization_token;
                cartForm.submit("get", paymentPayload.value.data.return_url, {
                  preserveScroll: false,
                  preserveState: false,
                  onFinish: () => (blockUI.value = false),
                  onError: () => (blockUI.value = false),
                });
              }
            });
          },
        });
      } else {
        cartForm.submit("post", "/payment/pay/" + defaultPayment.value.integration, {
          preserveScroll: false,
          preserveState: false,
          onFinish: () => (blockUI.value = false),
          onError: () => (blockUI.value = false),
        });
      }
    } else {
      // TODO: Dirty hack for mobile
      try {
        const elements = Array.from(htmlForm.querySelectorAll("input:required, select:required"));
        const firstFailElem = elements.find((item) => item.checkValidity() === false);
        if (firstFailElem) {
          firstFailElem.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            firstFailElem.reportValidity();
          }, 600);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return {
    cartForm,
    pickupPoints,
    salesDeliveries,
    defaultShipping,
    defaultPayment,
    paymentPayload,
    landedCostAccepted,
    reloadCart,
    clearCart,
    submitCart,
  };
});

const usePaymentMethods = createSharedComposable(() => {
  if (!isCartPage()) return;

  const { setCart } = useCart();

  const { cartForm, defaultShipping, defaultPayment, paymentPayload } = useCartPage();

  const paymentIntegration = ref(null);

  const paymentMethods = computed(() => (defaultShipping.value ? defaultShipping.value.paymentMethods : []));

  // TODO GLOBAL COMPONENTS
  const setIntegration = (option) => {
    const object =
      {
        klarna_payments: {
          component: defineAsyncComponent(() => import("g/Components/Cart/GlobalKlarnaPaymentsWidget.vue")),
          props: {
            paymentPayload: paymentPayload.value,
            integrationType: option.item.integration_type,
          },
        },
        resursbank: {
          component: defineAsyncComponent(() => import("g/Components/Cart/ResursbankInput.vue")),
          props: {},
        },
        payment_highway: {
          component: defineAsyncComponent(() => import("g/Components/Cart/PaymentHighwayInputs.vue")),
          props: {},
        },
      }[option?.item.integration] ?? {};
    paymentIntegration.value = object;
    return object;
  };

  const updatePayment = (paymentId = null) => {
    if (cartForm.payment_id === parseInt(paymentId)) return;
    // console.log(payment_id, 'payment');

    blockUI.value = true;

    window.axios
      .post("/cart/save/payment", {
        paymentId: paymentId,
      })
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          setCart(response.data.cart);
          defaultPayment.value = defaultShipping.value.paymentMethods.find((payment) => payment.id == paymentId);
          paymentPayload.value = response.data?.payment_payload ?? {};
          cartForm.payment_id = paymentId;
          blockUI.value = false;
        }
      })
      .catch((error) => {
        console.error(error);
        blockUI.value = false;
      });
  };

  return {
    defaultPayment,
    paymentMethods,
    paymentIntegration,
    setIntegration,
    updatePayment,
  };
});

const useShippingMethods = createSharedComposable(() => {
  if (!isCartPage()) return;

  const { setCart } = useCart();

  const { cartForm, pickupPoints, salesDeliveries, defaultShipping, landedCostAccepted } = useCartPage();

  const reshipActive = $page.props.reshipActive ?? null;

  const setPickupPoints = (value) => {
    pickupPoints.value = value;
  };

  const setShippingID = (deliveryID, shippingID) => {
    salesDeliveries.value.find((delivery) => delivery.id === deliveryID).selectedShippingId = shippingID;
  };

  const updateReship = (deliveryId, shippingId) => {
    deliveryId = parseInt(deliveryId);
    shippingId = parseInt(shippingId);

    if (cartForm.shipping_id === parseInt(shippingId)) return;

    blockUI.value = true;

    window.axios
      .post("/cart/save/shipping", {
        deliveryId: deliveryId,
        shippingId: shippingId,
      })
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          // setDefaultShipping();
          defaultShipping.value = $page.props.shippingMethods.find((shipping) => shipping.id === shippingId);
          setShippingID(deliveryId, shippingId);
          setPickupPoints(response.data.pickup_points);
          setCart(response.data.cart);
          landedCostAccepted.value = response.data?.landedCostAccepted ?? null;
          cartForm.shipping_id = shippingId;
          blockUI.value = false;
        }
      })
      .catch((error) => {
        console.error(error);
        blockUI.value = false;
      });
  };

  // OLD CART SHIPPING UPDATE
  const updateShipping = (shippingId = null) => {
    if (cartForm.shipping_id === parseInt(shippingId)) return;

    const inertiaOptions = {
      preserveState: true,
      preserveScroll: true,
      onFinish: () => (blockUI.value = false),
    };
    blockUI.value = true;

    if (shippingId) {
      cartForm.shipping_id = shippingId;
    }
    // Reset servisepoints on shipping change
    updateShippingAndPayment(cartForm.shipping_id, cartForm.payment_id, inertiaOptions);
  };

  return {
    defaultShipping,
    salesDeliveries,
    reshipActive,
    updateReship,
    updateShipping,
  };
});

const usePickupPoints = createSharedComposable(() => {
  if (!isCartPage()) return;

  const { cartForm, pickupPoints } = useCartPage();

  const selectedPoint = ref(pickupPoints.value.find((point) => point.id === $page.props?.selected_pickuppoint ?? ""));

  const checkPickupPoints = (address) => {
    if (address.postal.trim() === "" || SSR) {
      return;
    }

    window.axios
      .get("/cart/servicepoints", { params: { address } }) // payment id mukaan ?
      .then((response) => {
        if (response.data) {
          pickupPoints.value = response.data;
        }
      })
      .catch((error) => {
        blockUI.value = false;
        console.error(error);
      });
  };

  let timeout = null;
  const changeShippingAddress = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      checkPickupPoints(value);
    }, 200);
  };

  watch(
    () => pickupPoints.value,
    (newPoints) => {
      if (!selectedPoint.value) {
        selectedPoint.value = newPoints[0];
      } else if (newPoints && newPoints.length) {
        const match = newPoints.find((point) => point.id === selectedPoint.value.id);
        if (!match) {
          selectedPoint.value = newPoints[0];
        }
      }
    },
    { immediate: true },
  );

  watch(
    () => selectedPoint.value,
    (newPoint) => {
      if (SSR) return;
      if (newPoint && newPoint !== "default") {
        // console.log(newPoint);
        window.axios.post("/cart/save/pickuppoint", { point: newPoint }).catch((error) => {
          console.error(error);
        });
      }
    },
  );

  watch(
    () => [cartForm.shipping_address, $page.props?.defaultShipping],
    ([newAddress], [oldAddress]) => {
      if (SSR) return;
      if (JSON.stringify(newAddress) == JSON.stringify(oldAddress)) return;
      changeShippingAddress(newAddress);
    },
    { deep: true },
  );

  return {
    pickupPoints,
    selectedPoint,
  };
});

const useAddress = (params) => {
  // Address logic here

  if (!isCartPage()) return;
};

const useSummary = (params) => {
  // Summary logic here

  if (!isCartPage()) return;

  // TODO GlobalShippingPrice.js stuff here
};

const useKlarnaPayments = () => {
  if (!isCartPage()) return;

  const { paymentPayload } = useCartPage();

  const initKlarnaPayments = () => {
    if (paymentPayload.value && paymentPayload.value.data) {
      if (typeof window !== "undefined") {
        loadScript("https://x.klarnacdn.net/kp/lib/v1/api.js").then(() => {
          window.Klarna.Payments.init({ client_token: paymentPayload.value.data.client_token });
          const event = new Event("klarna-after-payments-init");
          window.dispatchEvent(event); // event for the klarna widget component
        });
      }
    }
  };

  onBeforeMount(() => {
    initKlarnaPayments();
  });

  watch(
    () => paymentPayload.value,
    () => {
      initKlarnaPayments();
    },
  );
};

export {
  useCart,
  useCartPage,
  usePaymentMethods,
  useShippingMethods,
  usePickupPoints,
  useKlarnaPayments,
  // useAddress,
};
