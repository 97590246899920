<template>
  <span class="relative">
    <svg v-bind="$attrs" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2315_9574)">
        <path
          d="M11.6 13.6L10.25 16.05C9.9 16.7 9.92 17.35 10.3 18.01C10.68 18.67 11.25 19 12 19H24V17H12L13.1 15H20.55C20.92 15 21.25 14.91 21.56 14.72C21.87 14.53 22.11 14.28 22.3 13.95L25.85 7.55C26.03 7.2 26.02 6.85 25.82 6.51C25.62 6.17 25.33 6 24.94 6H10.2L9.25 4H6V6H8L11.6 13.6ZM23.3 8L20.55 13H13.55L11.15 8H23.3Z"
          fill="#1C1B1F"
        />
        <path
          d="M11 22.9302C10.73 22.9302 10.49 23.0302 10.29 23.2202C10.09 23.4102 10 23.6502 10 23.9302C10 24.2102 10.1 24.4402 10.29 24.6402C10.49 24.8402 10.72 24.9302 11 24.9302C11.28 24.9302 11.51 24.8302 11.71 24.6402C11.91 24.4502 12 24.2102 12 23.9302C12 23.6502 11.9 23.4202 11.71 23.2202C11.51 23.0202 11.28 22.9302 11 22.9302Z"
          fill="#1C1B1F"
        />
        <path
          d="M21 22.9302C20.73 22.9302 20.49 23.0302 20.29 23.2202C20.09 23.4102 20 23.6502 20 23.9302C20 24.2102 20.1 24.4402 20.29 24.6402C20.49 24.8402 20.72 24.9302 21 24.9302C21.28 24.9302 21.51 24.8302 21.71 24.6402C21.91 24.4502 22 24.2102 22 23.9302C22 23.6502 21.9 23.4202 21.71 23.2202C21.51 23.0202 21.28 22.9302 21 22.9302Z"
          fill="#1C1B1F"
        />
        <path
          d="M16 22.9302C15.73 22.9302 15.49 23.0302 15.29 23.2202C15.09 23.4102 15 23.6502 15 23.9302C15 24.2102 15.1 24.4402 15.29 24.6402C15.49 24.8402 15.72 24.9302 16 24.9302C16.28 24.9302 16.51 24.8302 16.71 24.6402C16.91 24.4502 17 24.2102 17 23.9302C17 23.6502 16.9 23.4202 16.71 23.2202C16.51 23.0202 16.28 22.9302 16 22.9302Z"
          fill="#1C1B1F"
        />
        <path
          d="M21.98 19.9302H10C7.79 19.9302 6 21.7202 6 23.9302C6 26.1402 7.79 27.9302 10 27.9302H21.98C24.19 27.9302 25.98 26.1402 25.98 23.9302C25.98 21.7202 24.19 19.9302 21.98 19.9302ZM21.98 25.9302H10C8.9 25.9302 8 25.0302 8 23.9302C8 22.8302 8.9 21.9302 10 21.9302H21.98C23.08 21.9302 23.98 22.8302 23.98 23.9302C23.98 25.0302 23.08 25.9302 21.98 25.9302Z"
          fill="#1C1B1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2315_9574">
          <rect width="19.98" height="23.93" fill="white" transform="translate(6 4)" />
        </clipPath>
      </defs>
    </svg>
    <div v-if="amountInCart" class="absolute flex items-center justify-center top-0 left-3 sm:left-4 bg-[#903221] rounded-full w-4 h-4">
      <div class="text-[10px] text-white text-sans">{{ amountInCart }}</div>
    </div>
  </span>
</template>

<script setup>
import { computed } from "vue";
import { useCart } from "g/Composables/Cart.js";

const { cart } = useCart();

const amountInCart = computed(() => {
  const amount = cart.value?.amount;
  return amount > 9 ? "+9" : amount;
});
</script>
